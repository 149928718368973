import { PropsWithChildren, useCallback } from 'react';

import {
  GenericPermission,
  Permission,
  fromPermissionApi,
  isPermissionRangeLower,
} from '../../api/resources/permissionResource';
import { useAuth } from '../../contexts/AuthCtx';
import ErrorPage from '../../pages/utilityPages/InvalidRoutePage';

// this is a copy of the available permissions
// it is stored here to check that changing a permission somewhere in the code will still be valid
// to get this list go to the internal tools page in development mode
const AVAILABLE_PERMISSIONS = [
  'ui.access',
  'hierarchy.ui',
  'authorization.ui',
  'settings.ui',
  'dashboard.ui',
  'internal-tools.ui',
  'adoption.ui',
  'user.ui',
  'administrator.list',
  'business.list',
  'customer.list',
  'partner_account.list',
  'account.list',
  'permission.list',
  'permission.edit',
  'tags_visibility.use',
  'administrator.assign',
  'business.assign',
  'tags_visibility.edit',
  'editor.list',
  'editor.assign',
  'user_management',
  'user.management',
  'document.list',
  'document.list.own',
  'document.list.tenant',
  'roles.edit',
  'user.log_as',
  'user.invite.own',
  'user.invite.tenant',
  'user.metadatatype',
  'tags.use.own',
  'tags.use.tenant',
  'tags.create.own',
  'tags.create.tenant',
  'chat.list.own',
  'chat.list.tenant',
  'chat.create.own',
  'chat.create.tenant',
  'channel.list.own',
  'channel.list.tenant',
  'channel.create.own',
  'channel.create.tenant',
  'groupchat.list.own',
  'groupchat.create.tenant',
  'broadcast.list.own',
  'broadcast.list.tenant',
  'broadcast.create.own',
  'broadcast.create.tenant',
  'broadcast.target_user.own',
  'broadcast.target_user.tenant',
  'broadcast.target_channel.own',
  'broadcast.target_channel.tenant',
  'broadcast.target_groupchat.own',
  'broadcast.target_groupchat.tenant',
  'broadcast.action_sign',
  'broadcast.social_post',
  'broadcast.social_share',
  'document.create.own',
  'document.create.tenant',
  'document_pool.list',
  'document_pool.list.own',
  'document_pool.list.tenant',
  'document_pool.create.own',
  'document_pool.create.tenant',
  'signs_editor',
  'flow.list.own',
  'flow.list.tenant',
  'flow.create',
  'flow.edit',
  'monitor.ui',
  'export.ui',
  'template.ui',
  'domain.edit',
  'domain.switch',
  'domain.ui',
  'ticket.ui',
  'badge.ui',
  'action.assignToUsers',
  // --------------------
  'non.existing.permission',
] as const;

export type AvailPermission = typeof AVAILABLE_PERMISSIONS[number];

// this obj could be used also in the permission resource, but I would not give full trust
// that the availPermissions list will stay synched with the DB, since it is required only for hiding parts of the UI
const availPermissionsByApiName = AVAILABLE_PERMISSIONS.reduce(
  (acc, permApi) => {
    const perm = fromPermissionApi(permApi);
    if (perm) acc[permApi] = perm;
    return acc;
  },
  {} as { [permissionApi in AvailPermission]: GenericPermission }
);

const canAccess = (
  isBusiness: boolean | undefined,
  requiredPermissions: AvailPermission[] | undefined,
  userPermissions: Permission[] | undefined
) =>
  // isBusiness ||
  canAccessPermissions(requiredPermissions, userPermissions);

const canAccessPermissions = (
  requiredPermissions: AvailPermission[] | undefined,
  userPermissions: Permission[] | undefined
) => {
  if (requiredPermissions === undefined) return true;
  if (userPermissions === undefined) return false;

  return requiredPermissions.every(reqPermApi => {
    const perm = availPermissionsByApiName[reqPermApi];

    return userPermissions.some(
      userPerm =>
        perm.entity === userPerm.entity &&
        perm.action === userPerm.action &&
        !isPermissionRangeLower(userPerm.range, perm.range)
    );
  });
};

type PermissionsGuardProps = PropsWithChildren<{
  requiredPermissions?: AvailPermission[];
  showMsg?: boolean;
}>;

// FIXME remove this
const SUPER_EMAILS = new Set([
  'wealth.applicativi@cassalombarda.it',
  'viviana.osculati@thefutureapp.io',
  'viviana.osculati@futureinvest.io',
  'andrea.guidobono@thefutureapp.io',
]);

export const useCanAccess = () => {
  const { user } = useAuth();
  const { isBusiness, permissions, email } = user ?? {};

  return useCallback(
    (requiredPermissions: AvailPermission[] | undefined) =>
      SUPER_EMAILS.has(email ?? '')
        ? true
        : permissions
        ? canAccess(isBusiness, requiredPermissions, permissions)
        : undefined,
    [isBusiness, permissions, email]
  );
};

function PermissionsGuard({
  requiredPermissions,
  children,
  showMsg,
}: PermissionsGuardProps) {
  const canAccess = useCanAccess();

  if (canAccess(requiredPermissions)) return <>{children}</>;
  else return <>{showMsg ? <ErrorPage errorType='permissions' /> : null}</>;
}

export default PermissionsGuard;

export const useCanAccessUI = () => {
  const { user } = useAuth();

  const { rawPermissions } = user ?? {};

  return rawPermissions?.includes('ui.access');
};
